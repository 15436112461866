import React from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import ErrorMessage from './ErrorMessage';
import Footer from '../../Shared/Footer';
import Helmet from 'react-helmet';

const Error404 = (data) => {
    return (
        <>
            <Helmet>
                <title>{data['404'].pageTitle}</title>
                {
                    data['404'].meta.map((item, index) => {
                        if (item.property) {
                            return <meta property={item.property} content={item.content} key={index} />;
                          } else if (item.name) {
                            return <meta name={item.name} content={item.content} key={index} />;
                          } else {
                            return null;
                          }
                    } )
                }
            </Helmet>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                headerNormalLogo={require(`../../../../${data.shared.homeOrangeLogoDark}`)}
                headerStickyLogo={require(`../../../../${data.shared.homeOrangeLogoLight}`)}
                signUpBtn='inner-btn'
                {...data}
            />
            <BreadCums pageTitle={data['404'].title} />
            <ErrorMessage {...data}/>
            <Footer {...data} />
        </>
    );
};

export default Error404;