import React from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import BlogDetailsData from './BlogDetailsData';
import Footer from '../../Shared/Footer';
import Helmet from 'react-helmet'

const BlogDetails = (data) => {
    return (
        <>
            <Helmet>
                <title>{data.terms.pageTitle}</title>
                {
                    data.terms.meta.map((item, index) => {
                        if (item.property) {
                            return <meta property={item.property} content={item.content} key={index} />;
                          } else if (item.name) {
                            return <meta name={item.name} content={item.content} key={index} />;
                          } else {
                            return null;
                          }
                    } )
                }
            </Helmet>
            <Header
                parentMenu='blog'
                headerClass='sc-header-section inner-header-style'
                headerNormalLogo={require(`../../../../${data.shared.homeOrangeLogoDark}`)}
                headerStickyLogo={require(`../../../../${data.shared.homeOrangeLogoLight}`)}
                signUpBtn='inner-btn' 
                {...data}
            />
            <BreadCums pageTitle={data.terms.title} />
            <BlogDetailsData {...data} />
            <Footer {...data} />
        </>
    );
};

export default BlogDetails;